import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Table`}</inlineCode>{` merupakan gabungan dari berbagai macam data - relevan satu sama lain, ditampilkan dalam baris untuk memudahkan pemindaian.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard title="Default" titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1040px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABW0lEQVQoz21SCXLDIAz0/7/WVyRtbtscBsRhvBVge5ymzOwgCWlBaDtLEQXGBd5DtS3bzY+7b3f/EH+zGzpzKDY7cTlMbMc34j3vn5oNXQk6xmQdv8KDAhNZD20cxwPIJz4jjhGcj3tOiRu2yyurz6gv9CECecYoBBdZLEuG1hrD0CMnPkOGkqJi4Twzacw1voCcRYoBZeWc+RImlCbg3Hvc1YxTH/FQCaP2UDaCNyi34NZr3PsJ2uVqn68DpJ2haWlwCc9Bc1e+EX4z4U1EJg546QSpDQZhoftTbf3ybISqEL40fu6iEhZfmBmXh8D1MUIZWlteZkw0c1KCTzO34SFpxEl/QccnnLEgsrU1a/ilStavaCsjhAApVWt5G8o2MevXIZFDT9wGteFsn1/2MowylIq3AR1ls+qw+e0CYulYt0qDPiV0xBbr/gpzLyjkKywdRE9HkccPjf4CHzgHnpYE/4IAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/7e0241a8dbe8cd5aada826ac1a36a4ed/afe5f/default.png",
              "srcSet": ["/static/7e0241a8dbe8cd5aada826ac1a36a4ed/7fc1e/default.png 288w", "/static/7e0241a8dbe8cd5aada826ac1a36a4ed/a5df1/default.png 576w", "/static/7e0241a8dbe8cd5aada826ac1a36a4ed/afe5f/default.png 1040w"],
              "sizes": "(max-width: 1040px) 100vw, 1040px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard title="Alternate" titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1040px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABOUlEQVQoz62S2Y6DMAxF+f9/HGnUBQpkd2KSQHvHpItGo75NH45i5ybOxaaLXPBJurRU7HBeG3/jd9q7/ScdpQwbEpTxMI7gAkNbgpbc+SR5kjw0rOTtDPHrnPGxxTuUmsOClBJO5wHjrBFiwjhO+Po+QbsgGqPvB/TDAKKIaZrlofu+0hrOeTBneNGirJ2LBUddMPorDrpi8hv2vd4WzHRDzDdcNGHUEbRIrAjHwcDzBsq4wytmQ2LmWVAVDLbiIOsUNii/yGflVsDzFf3kW9Eg+aACThcLl7amWVnPo0U/avi4oONcUWuFIXHkMwJXsb6Al4y6bsIKYyxCIKzbBusclNIocmcVvZQqvZN+Goe9fd0+6iCD8WLXCSHec2qUhqelvf6KiV8aPX6XIHoUM93vkf8bfjj8JD+lKgPHjlnt7wAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/4b5476043ff7e8538ce77927d65dea3a/afe5f/alternate.png",
              "srcSet": ["/static/4b5476043ff7e8538ce77927d65dea3a/7fc1e/alternate.png 288w", "/static/4b5476043ff7e8538ce77927d65dea3a/a5df1/alternate.png 576w", "/static/4b5476043ff7e8538ce77927d65dea3a/afe5f/alternate.png 1040w"],
              "sizes": "(max-width: 1040px) 100vw, 1040px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard title="Total" titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1040px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABLklEQVQoz31SgY6DIAzd/3+kyW7JLU5FEJUW0XctOs/dvJE0ryl9j0fhMoSIfmQMgTeMWGvxJJ9O9vgFL383VNgPYTuA9lzR+SHXRu0fKOev3E1wL0hDURRo6hrzPOPxqPB1u4GIEEKAtRYxTijLEtfrFcwMiuldMLsRHAXLqpGcMaVFCJTFlmVBSknEIoAFxpgcuoinE4cHl42fcG8JtieY1qI2NpMa0+L7XsL3I1rn5eAaI7FEPHP4WzSeUTmZmQzeuQ5WIk4pY1U3cguC6zzqpgXL9QOdOdRXezrsCKUVUs9ZxFgnRBX0ImjynFu7igdx99GhbuqQiVMW6XwvJJ3nLG797lCdkdTV+anD45WDzCsHrY+lhJyLs16+zvjW8+HbKB7jWDt+8v/6FH8AlbAHR4RdNNMAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/6b1143b16756ba24b55be64a44620ea8/afe5f/total.png",
              "srcSet": ["/static/6b1143b16756ba24b55be64a44620ea8/7fc1e/total.png 288w", "/static/6b1143b16756ba24b55be64a44620ea8/a5df1/total.png 576w", "/static/6b1143b16756ba24b55be64a44620ea8/afe5f/total.png 1040w"],
              "sizes": "(max-width: 1040px) 100vw, 1040px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard title="Multiline" titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1040px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABv0lEQVQoz01S2W7cMAz0//9Sn/uQpyBpErQNsnX28vpYS7JsS7aOKcmNgxVAUKRGFDXDYnIr2NwSMfuAaV4wktnJYyST+GsvudkLXrDuhrNfOM4XbglY1xVlWWIcR7IJXddBa41hGBBjhLUWSikYYwSjtULb1EgpSV4RNqUMrlU4emkNAU3TYJ5nOQgUhxDF8yU2xizLgkx7/sG5tcg504MJ3i90xl1Th56qIke6FMFrmkbsP0vUl4t0EsKKqjqja1uM1CljO7PgaacEn3OSX1k7CgWFnVf0Y6DqxOGSIbGZoK3DMBF/LqLuDNp+oM6Y6wQz0Y+0J3yS2K8B3JhwyK/92s84tA6VyXg/Gjw8feDx9xFv/zp8th7P7xVedy3+HDRO1wUvhP/x2OHUR5xVwL62KCsDZT2KYVrRmIRKR1w0eQKc+0Dg9Xv//PeEt119wxCWcbX4SLlERZN4Q7UKJpLbdc4jEyfMx+F4IqWvMKQyk933Cte+R680iUCPdTN+vraIImCEMjQFepCREpU3JXmxktuIsOo8NhcSiHOMYWXZh3DbbzGbY5W3wRajceCOeci/B/3ujFXc4vn+3p39ByDMAnmXvWnvAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/ccd4ce9d679460c5a80a2f40d716645f/afe5f/multiline.png",
              "srcSet": ["/static/ccd4ce9d679460c5a80a2f40d716645f/7fc1e/multiline.png 288w", "/static/ccd4ce9d679460c5a80a2f40d716645f/a5df1/multiline.png 576w", "/static/ccd4ce9d679460c5a80a2f40d716645f/afe5f/multiline.png 1040w"],
              "sizes": "(max-width: 1040px) 100vw, 1040px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard title="Fixed Column" titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1040px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABzElEQVQoz0VTi27bMAzs/3/b0GEYBrQo1rhJnDi1rZclWS/7RjJpJ4C2SEvHk+785GNBXDO0cQgx3efWwTovwbkPEcYu37U1FeRSobWR+poyrSvwMeOJH6U2dF2HYRjQWoNzDusaEWOUvJSCELzkMQSp8TA2wHsv81q3L8AiHRlk2zYJa60EL845C5Al1suyIKUkAHvLcP0zNYpQxDTEFYFYPhhW2nhfyN2Z6TzPAsg5z7nGTLnhvu8MieiN1Frb5AqEYcoFh1vE68lA+YbRVXQXhUOv8HE1GNSKfvRU0zgOFpc5YPY7ZpfQvz9j0h7DaKDdg2Gk415Uxmsf0OsNH2PBz5cev/9+UhMr+a+3AT/+HNHdEk5zxVlt6FXDe69xmjKOU8Vo7+I+MaoNBTfqbOMmMdlMDDLUUqB9xXVacLxMGGYvNRt32kP1/oBPHXGdI/SS/98hn3+Nge5mI7UKusMB5/OJ7m5CJhHmicCGK4kTUEik+2gI05t8Z0ewdVhgUTnRsVkA9pVSmuzgSPUFIwHNSsnbGIOvwcJUshp7jx2QMgv7sA3TDDRZfPwO7pYyqRZWLGSLSiq2bZeT5NLEZmFNtDaIsQ3/AOlu7H9lqgFXuTgJxgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/16c80132089023c621280c023d779fe0/afe5f/fixed.png",
              "srcSet": ["/static/16c80132089023c621280c023d779fe0/7fc1e/fixed.png 288w", "/static/16c80132089023c621280c023d779fe0/a5df1/fixed.png 576w", "/static/16c80132089023c621280c023d779fe0/afe5f/fixed.png 1040w"],
              "sizes": "(max-width: 1040px) 100vw, 1040px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard title="Compact Default" titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1040px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABKklEQVQoz61SXW+DMAzk//+8SZumadK0tpQBJYF8QkJ3s5Owrl0f+2BxtvHlnEtlfYBxy0OCuaoNXDVufrrF/L2HDRNeBgLcHEuT8LLCUs69hH3GWy2TReg/h1m/ZIWOEucc2n6AMo7wjK+2g5AS87wQbjGOU8JcCyFiXgKkHBFipFjLVkXhZGZ8dhb1yaEWC1ph8Pz6nuqGVOyPHXqhSN16hZXlTc4YpCIhPt/hRrjrPY4yYD8ENCeNp5c3GjqnwUPT4yQ1DX9jV7doOpGIOO8GhY9dfSFkmby753V0jlE77A9NWo1DCAljLCKtNgwC06QSjusKYx3EqH6NqZiMSfNd5uCmJyO4posRXGOcjctm6GKGK0YVU7JCU9zdyP89iTtP6F6vevTD/gFzqQV3N/FLgQAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/456ca73332d0b474c2ac74dcb24ce046/afe5f/compact-default.png",
              "srcSet": ["/static/456ca73332d0b474c2ac74dcb24ce046/7fc1e/compact-default.png 288w", "/static/456ca73332d0b474c2ac74dcb24ce046/a5df1/compact-default.png 576w", "/static/456ca73332d0b474c2ac74dcb24ce046/afe5f/compact-default.png 1040w"],
              "sizes": "(max-width: 1040px) 100vw, 1040px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard title="Compact Alternate" titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1040px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABE0lEQVQoz62SX2+DIBTF+/0/XpMt2V5atVoRFAQEdTZnXJTOLtubDyf8uIST++80+BnWTYeIvE4JDjNMZnT6aYEbvzZ+BF52vMbdtMYib7Ek8okZunBRSiMrKjRCoZU9rvkN1Z1BKhO5ZjywjjGlLVRvcK8b9HpAb9xrhr0dcakt8sYg5w4V1zi/faDrXXibcC1q1FxBD/PGMrLUHsbNMQlt/athxj3KbkYuZlTC4Pz+CeuXqKLi4J3BMD6Q3Rgq1kW2QazVuOTlj6F1VP8EM4xg0gV5CGlDmSUGN0Yx3sZyqTU1ExCtDDxFUcnUomcPyYxMSfQhiQaUppeav/I6uP1k9/dnhv+twX6lfvNfb6ejF/sbFBoC8UAFizgAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/4fdbeefb55a4ca9169533412addba6ef/afe5f/compact-alternate.png",
              "srcSet": ["/static/4fdbeefb55a4ca9169533412addba6ef/7fc1e/compact-alternate.png 288w", "/static/4fdbeefb55a4ca9169533412addba6ef/a5df1/compact-alternate.png 576w", "/static/4fdbeefb55a4ca9169533412addba6ef/afe5f/compact-alternate.png 1040w"],
              "sizes": "(max-width: 1040px) 100vw, 1040px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard title="Compact Row Child" titleColor="dark" aspectRatio="2:1" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1040px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "50%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABVElEQVQoz61S2XKDMAzM//9epzkmgQbKjRNTHxibpFvZOfCkfezDotWJLGmljYMa7b/A11o9iMc4zSTnF51kxGO82nyt1YNIPWH4UviSGlIZkgY86CMGoZ/cw8cLdfMPpPvcZ4f+I8jAB4kkb5AWDIwrrDc7JB8ZJWrknwWS9AghPS9R1Q2UNujZmZqQlCvIN94KmsmhPBkcmglZb5G0pJ8v2CQlSjbipICsEchbCSavKHqNtOToxRUnDTD1jbzm4MKE0VCHFoPyRRze9hW2xx4V8fdDjR3xvNPYpC3WaUNFNSrugr/mc0B1tigYjUs9l+IHSjNUIyrqNOuoKzFhvd2joye5+YquZ2jaDpOd0XYsjMC/zM2XkJflZZhptGUb2g0LuksZAmxI1I+YO1fjFLiHCZu2r1teblFHN7XYIx6dyAIX36G7/+F3wFL4bx7fn77bfgCZgQBllc7AEwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/9f1545c7177c493fa13b2a7992fdc141/afe5f/compact-row-child.png",
              "srcSet": ["/static/9f1545c7177c493fa13b2a7992fdc141/7fc1e/compact-row-child.png 288w", "/static/9f1545c7177c493fa13b2a7992fdc141/a5df1/compact-row-child.png 576w", "/static/9f1545c7177c493fa13b2a7992fdc141/afe5f/compact-row-child.png 1040w"],
              "sizes": "(max-width: 1040px) 100vw, 1040px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      